<!-- 常见问题 -->
<template>
    <div class="commonProblem">
        <div v-for="(item, index) in list" :key="index">
            <p>{{ index + 1 }}.{{ item.art_title }}</p>
            <span>{{ item.art_content }}</span>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            list: [

            ],
        };
    },

    components: {},

    mounted() {
        this.getList();
    },

    methods: {
        getList() {
            this.$axios
                .get("/api/Web/getArticleList", {
                    params: {
                        arc_code: "question",
                    },
                })
                .then((res) => {
                    if (res.code == "000") {
                        console.log(res);
                        this.list = res.data.rows;
                    }
                });
        },
    },
};
</script>
<style lang='scss'>
.commonProblem {
    margin: 10px 20px 10px 0;
    font-size: 14px;
    font-weight: 500;

    header {
        padding-top: 10px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
    }

    p {
        font-size: 15px;
        font-weight: 600;
        margin: 10px 0
    }
}
</style>